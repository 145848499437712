
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
export default Vue.extend<Data, Methods, Computed>({
  data() {
    return {
      url:
        process.env.VUE_APP_ENV === 'production'
          ? 'https://intro.cellook.kr/notice?referrer=cellook'
          : 'https://intro-alpha.cellook.kr/notice?referrer=cellook',
    }
  },
  created() {
    localStorage.setItem('IS_VIEW_NOTICE', 'true')

    if (this.getNoticeNo) {
      if (process.env.VUE_APP_ENV === 'production') {
        this.url = `https://intro.cellook.kr/${this.getNoticeNo}?referrer=cellook`
      } else {
        this.url = `https://intro-alpha.cellook.kr/${this.getNoticeNo}?referrer=cellook`
      }
    }

    this.fetchNoticeNo({ no: '' })
  },
  computed: {
    ...mapGetters('RouteHistoryStore', ['getPreviousPage']),
    ...mapGetters('CheckInStore', ['getNoticeNo']),
  },
  methods: {
    ...mapActions('CheckInStore', [
      'fetchNoticeNo',
      'fetchCheckInStoreScrollLocation',
    ]),

    onClickPrevIcon() {
      if (
        this.getPreviousPage.name &&
        this.getPreviousPage.name === 'CategoryMain'
      ) {
        this.$router.push('/categoryMain')
      } else if (
        this.getPreviousPage.name &&
        this.getPreviousPage.name === 'CheckIn'
      ) {
        this.$router.push(this.getPreviousPage.fullPath)
      } else {
        this.$router.push('/mypage')
      }
    },
  },
})
interface Data {
  url: string
}

interface Computed {
  getPreviousPage: {
    fullPath: string
    name: string | null
    path: string
  }
  getNoticeNo: string
}
interface Methods {
  onClickPrevIcon(): void
  fetchNoticeNo: (payload: { no: string }) => Promise<void>
}
